import React from 'react';
import './styles.css'

import yt from '../../assets/links/yt.png'
import wpp from '../../assets/links/wpp.png'
import fb from '../../assets/links/fb.png'
import tlg from '../../assets/links/tlg.png'
import me from '../../assets/links/me.png'
import karita from '../../assets/links/karita.png'
import giulia from '../../assets/links/giulia.png'

function Links() {
    return (
        <>
            <section className="bg1y d-flex flex-column align-items-center">

                <img src={karita} className="img-fluid links__giulia" alt="Links"></img>

                <a href="https://www.instagram.com/drakaritalima/" className="mt-3 mb-4">
                    <img src={giulia} className="img-fluid insta__link" alt="Manuela"></img>
                </a>

                {
                    dataLinks.map((element, index) => {
                        return (
                            <div className="col-lg-4 d-flex flex-column links__card bg-white br-25 p-5 mb-5 text-center">
                                {
                                    element.logo ?
                                        <div className="d-flex flex-row justify-content-center align-items-center mb-4 mt-3">
                                            <img src={element.logo} className="img-fluid curso__logo w-100" alt="logo"></img>
                                        </div>
                                        :
                                        <></>
                                }
                                <p className="links__subtitle dark" key={index}>
                                    {element.subtitle}
                                </p>

                                <p className="links__title gray-333 m0">
                                    {element.title}
                                </p>

                                <p className="links__date dark">
                                    {element.date}
                                </p>

                                {
                                    element.texts ?
                                        element.texts.map((elementTwo, indexTwo) => {
                                            return (
                                                <p key={indexTwo} className="links__text font-14">
                                                    {elementTwo}
                                                </p>
                                            )
                                        }) : <></>
                                }
                                <a href={element.link}>
                                    <button className={element.active ? "btn links__button btn-danger br-25" : "btn links__button btn-danger br-25 disabled"}>
                                        {element.buttonText}
                                    </button>
                                </a>
                            </div>
                        )
                    })
                }

            </section>
            <section className="a4 d-flex flex-column align-items-center py-5 links__footer px-4">

                <div className="col-lg-6 d-flex flex-row flex-wrap justify-content-center align-items-center">

                    <div className="col-lg-4">

                        <img src={me} className="img-fluid mb-4" alt="Manuela"></img>

                    </div>

                    <div className="col-lg-1">
                    </div>

                    <div className="col-lg-7">

                        <p className="link-sub__title white mb-4">
                            Conheça sua Nutri
                        </p>
                        <p className="white font14">
                            Dra. Kárita Lima, nutricionista, terapeuta ayurvédica, psico. transpessoal, doula, e health coach, atua na área da saúde e alimentação desde 2007 e já ajudou mais de 3000 mulheres a desenvolverem um relacionamento saudável com a comida e com o seu corpo, encontrando a paz e a alegria de viver
                        </p>
                    </div>

                </div>

                <p className="white font10 wg7 text-uppercase mt-4">
                    Minhas Redes Sociais
                </p>

                <a href="https://t.me/karitalima" className="btn btn-warning btn-tlg mb-2">
                    <img src={tlg} className="img-fluid" alt="Manuela"></img>
                </a>
                
                <a href="https://www.facebook.com/drakaritalima" className="btn btn-warning btn-fb mb-2">
                    <img src={fb} className="img-fluid" alt="Manuela"></img>
                </a>

                <a href="https://www.youtube.com/channel/UCqVSdjKqjjc_wMOIdZQj6RQ" className="btn btn-warning btn-youtube mb-2">
                    <img src={yt} className="img-fluid" alt="Manuela"></img>
                </a>

                <a href="https://api.whatsapp.com/send?phone=5519981076996" className="btn btn-warning btn-wpp mb-2">
                    <img src={wpp} className="img-fluid" alt="Manuela"></img>
                </a>

            </section>
        </>


    );
}

export default Links;

const dataLinks = [
    {
        // logo: outroslinks,
        subtitle: "",
        title: "Dieta da Liberdade",
        date: "Curso 100% gratuito e online!",
        texts: [ 
            "Dê um basta na sua busca incessante por dietas mágicas e aprenda de uma vez por todas a se tornar uma mulher magra de corpo e alma!",
        ],
        buttonText: "Em breve!",
        active: false,
        // link: ""
    },
    {
        // logo: outroslinks,
        subtitle: "",
        title: "Encontro Semanal Nutrindo o Ser",
        // date: "De 00 até 00 de Xxxx",
        texts: [
            "Toda terça-feira às 11:00 você vai poder estar comigo para nutrir seu Ser. Nesta jornada semanal, vou te ajudar e ser Magra de Corpo e Alma."
        ],
        buttonText: "Inscreva-se para saber mais!",
        active: true,
        link: "http://aulas.karitalima.com.br/"
    },
    {
        // logo: outroslinks,
        subtitle: "",
        title: "Ebook Inverno quentinho: 8 receitas de sopas e caldos",
        // date: "De 00 até 00 de Xxxx",
        texts: [
            "Compartilho com você 8 receitas de sopas e caldos da minha cozinha direto para a sua casa.",
            "Deixe seu inverno mais quentinho!"
        ],
        buttonText: "Baixar ebook agora!",
        active: true,
        link: "http://sucostransformadores.kpages.online/ebook"
    },
    {
        // logo: outroslinks,
        subtitle: "",
        title: "Podcast Nutrindo o Ser",
        // date: "De 00 até 00 de Xxxx",
        texts: [
            "Novo episódio toda semana para você se tornar uma mulher magra de corpo e alma!"
        ],
        buttonText: "Ouvir agora!",
        active: true,
        link: "https://open.spotify.com/show/24o2XPbNENXfNklNYMzPFa?si=ku9Q83WcT82na3in49J2kg"
    },
    {
        // logo: outroslinks,
        subtitle: "",
        title: "Meu site pessoal",
        // date: "De 00 até 00 de Xxxx",
        texts: [
            "Conheça aqui o meu trabalho e saiba como posso te ajudar a melhorar sua relação com a comida, ter mais confiança e autoestima, escrevendo uma nova história!"
        ],
        buttonText: "Acessar agora!",
        active: true,
        link: "https://www.karitalima.com.br/"
    },
]
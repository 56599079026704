import React from 'react'
import './styles.css'

import aulaologo from '../../assets/aulaologo.png'
import timestamp from '../../assets/timestamp.png'
// import marker from '../../assets/marker.png'
import marker from '../../assets/marker.svg'
import expert1 from '../../assets/expert1.png'
import expert2 from '../../assets/expert2.png'
import separator from '../../assets/separator.svg'

export default function Aulao() {
    return (
        <>
            <div id="cadastro" className="w-100" style={{ height: "5px", backgroundColor: "#FFB28C" }}></div>
            <section className="aula__banner d-flex flex-column justify-content-center align-items-center py-5 px-4">
                <img src={auloes.aulalogo} className="img-fluid" alt="Imagem" />

                <div className="aula__badge mt-4 mb-3">
                    <img src={timestamp} className="img-" alt="Imagem" />
                    <span className="aula__time">
                        {auloes.timestamp}
                    </span>
                </div>

                <div className="col-lg-5 text-center">
                    <p className="aula__subtitle">
                        {auloes.subtitle}
                    </p>
                </div>

                <form method="POST" action="https://coalma.activehosted.com/proc.php" id="_form_9_" className="col-lg-4">
                    
                    <input type="hidden" name="u" value="9" />
                    <input type="hidden" name="f" value="9" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />

                    <input className="form-control mt-4 aula__input" id="fullname" name="fullname" placeholder="Seu Nome" type="text" required />
                    <input className="form-control mt-2 aula__input" id="email" name="email" placeholder="Seu Melhor E-Mail" type="email" required />

                    <button type="submit" className="btn btn-success aula__ctabtn mt-4 w-100 px-5">
                        {auloes.textbutton}
                    </button>
                </form>
            </section>










            <section className="d-flex flex-row flex-wrap justify-content-center align-items-center bglighty">

                <div className="col-lg-6 px-5 aulas__padding__fix">

                    <p className="aula__learn mb-5  ">O que você vai aprender:</p>

                    {
                        auloes.learn.map((element, index) => {
                            return (
                                <div className="aula__learn__text__mb d-flex flex-row">

                                    <img src={marker} className="aula__icon img-fluid" alt="Imagem" />

                                    <div className="col-lg-11 margin__left__aula">
                                        <span className="aula__learn__text">
                                            {element}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

                <img src={expert1} className="img-fluid col-lg-6 d-lg-block d-none" alt="Imagem" />

            </section>










            <section className="bg1y d-flex flex-column justify-content-center align-items-center py-5 px-4">

                <div className="col-lg-8 d-flex flex-row flex-wrap justify-content-center align-items-center ">

                    <img src={expert2} className="col-lg-4 img-fluid" alt="Imagem" />

                    <div className="col-lg-1 d-flex flex-column">
                    </div>

                    <div className="col-lg-6 d-flex flex-column p-4">

                        <img src={separator} className="mb-2 img-fluid aulas__separator" alt="Imagem" />

                        <p className="aulas__quemsou">Quem sou eu</p>

                        <p className="aulas__description">{auloes.description}</p>

                    </div>
                </div>

                <div className="col-lg-4">
                    <a href="/#cadastro" className="btn btn-success aula__ctabtn mt-5 w-100 px-5 d-flex flex-row flex-wrap justify-content-center align-items-center">
                        {auloes.textbuttonBottom}
                    </a>
                </div>

            </section>
            <div className="w-100" style={{ height: "5px", backgroundColor: "#FFB28C" }}></div>
        </>
    )
}

const auloes = {
    aulalogo: aulaologo,
    timestamp: "Toda terça-feira às 11h00",
    subtitle: "Seja avisada das melhores aulas para te ajudar a ser magra de corpo e alma e adquirir mais qualidade de vida e equilíbrio!",
    textbutton: "QUERO TIRAR MEUS PLANOS DO PAPEL",
    textbuttonBottom: "Quero me cadastrar nos aulões!",
    learn: [
        "A raiz do descontrole alimentar",
        "Comportamentos sabotadores da dieta",
        "Restrição alimentar e compulsão",
        "Emagreça assistindo TV",
        "Rotinas de uma pessoa magra de corpo e alma",
        "Ter mais bem-estar durante sua semana",
        "Equilibrar seus papéis de mulher e sua alimentação saudável",
        "Emagrecer com consistência, apenas mudando sua mentalidade",
        "Priorizar mais e se cuidar melhor",
        "Potencializar sua saúde e energia",
        "Dicas práticas para o dia a dia",
    ],
    description: "Dra. Kárita Lima, nutricionista, terapeuta ayurvédica, psico. transpessoal, doula, e health coach, atua na área da saúde e alimentação desde 2007 e já ajudou mais de 3000 mulheres a desenvolverem um relacionamento saudável com a comida e com o seu corpo, encontrando a paz e a alegria de viver."
}


import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Links from './pages/Links'
import Aulao from './pages/Aulao';
import Captura from './pages/Captura'
import Obrigado from './pages/Obrigado'
// import Principal from './pages/Principal'

function Routes() {

  return (
    <Router>

      <Switch>

        {/* <Route exact path="/" component={Links} /> */}
        {/* <Route exact path="/" component={Aulao} /> */}
        <Route exact path="/" component={Aulao} />
        <Route exact path="/obrigado" component={Obrigado} />
        {/* <Route exact path="/curso-gratuito" component={Captura} /> */}
        {/* <Route exact path="/principal" component={Principal} /> */}

      </Switch>

    </Router>
  );
}

export default Routes;
